<!--
 * @Description: 底部 我的 myself
 * @Author: zhoucheng
 * @Github: 无
 * @Date: 2021-04-01 00:35:37
 * @LastEditors: linf
-->
<template>
  <div class='body'>
    <!-- 头部背景图 -->
    <van-row class="top">
      <van-row>
        <img src="@/assets/mySelf/alarm.png"
             class="alarm"
             alt="">
      </van-row>
      <van-row class="lineHead">
        <van-col :span="5">
          <van-image class="head"
                     fit="cover"
                     :src="userInfo.avatar? $headPhotoUrl+userInfo.avatar : require('@/assets/mySelf/head.png')"
                     @click="handleClickPersonal" />
        </van-col>
        <van-col :span="19">
          <van-row class="inlinePhone">
            <van-col>{{userInfo.phoneNumber}}</van-col>
          </van-row>
          <van-row class="inlineCommit">
            <van-col>
              <img src="@/assets/mySelf/commit.png"
                   class="commit"
                   alt="">
            </van-col>
            <van-col class="commitText"
                     @click="handleClickCommit">
              {{commit}}
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </van-row>
    <van-row class="content">
      <!-- 我的钱包 -->
      <van-row class="contentDiv"
               @click="handleClickPackage">
        <van-col :span="2"></van-col>
        <van-col :span="2">
          <img src="@/assets/mySelf/package.png"
               class="img"
               alt="">
        </van-col>
        <van-col :span="19">我的钱包</van-col>
        <van-col :span="1">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <!-- 共享管理 -->
      <van-row class="contentDiv"
               @click="handleClickShare">
        <van-col :span="2"></van-col>
        <van-col :span="2">
          <img src="@/assets/mySelf/share.png"
               class="img"
               alt="">
        </van-col>
        <van-col :span="19">共享管理</van-col>
        <van-col :span="1">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <!-- 车辆管理 -->
      <van-row class="contentDiv"
               @click="handleClickCarMana">
        <van-col :span="2"></van-col>
        <van-col :span="2">
          <img src="@/assets/mySelf/carMana.png"
               class="img"
               alt="">
        </van-col>
        <van-col :span="19">车辆管理</van-col>
        <van-col :span="1">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <!-- 帮助中心 -->
      <van-row class="contentDiv"
               @click="handleClickHelp">
        <van-col :span="2"></van-col>
        <van-col :span="2">
          <img src="@/assets/mySelf/help.png"
               class="img"
               alt="">
        </van-col>
        <van-col :span="19">帮助中心</van-col>
        <van-col :span="1">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <!-- 意见反馈 -->
      <van-row class="contentDiv"
               @click="handleClickFedBack">
        <van-col :span="2"></van-col>
        <van-col :span="2">
          <img src="@/assets/mySelf/fedback.png"
               class="img"
               alt="">
        </van-col>
        <van-col :span="19">意见反馈</van-col>
        <van-col :span="1">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <!-- 通知管理 -->
      <van-row class="contentDiv"
               @click="handleClickInform">
        <van-col :span="2"></van-col>
        <van-col :span="2">
          <img src="@/assets/mySelf/about.png"
               class="img"
               alt="">
        </van-col>
        <van-col :span="19">通知管理</van-col>
        <van-col :span="1">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <!-- 关于我们 -->
      <van-row class="contentDiv"
               @click="handleClickAbout">
        <van-col :span="2"></van-col>
        <van-col :span="2">
          <img src="@/assets/mySelf/about.png"
               class="img"
               alt="">
        </van-col>
        <van-col :span="19">关于我们</van-col>
        <van-col :span="1">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
    </van-row>

    <div class="down">
      <van-tabbar v-model="active"
                  @change="onChange">
        <van-tabbar-item name="homePage"
                         icon="home-o"
                         @click="handleClickHomePage">首页</van-tabbar-item>
        <van-tabbar-item name="park"
                         icon="search"
                         @click="handleClickPark">
          停车</van-tabbar-item>
        <van-tabbar-item name="myself"
                         icon="friends-o">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import getUserInfo from '@/utils/getUserInfo.js'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      userCode: '',
      userInfo: {},
      // 底部我的图标显示选中
      active: 'myself',
      // 顶部手机号显示
      phoneNumber: '未登录',
      // 顶部是否认证
      commit: '未认证'
      // 头像
      // headPortrait: 'http://123.207.189.27:7198/cq-parking-h5/img/head.ed4cfb7e.png'
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // this.userCode = getUserInfo().userCode
    const userInfo = this.$cookie.get('userInfoDetails')
    if (userInfo) {
      this.userInfo = userInfo
    }
    this.getUserInfo()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryAuthenticate()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询是否已经认证
    queryAuthenticate () {
      this.$authenticateSelf.getIdIdentifyRecord({}).then(res => {
        if (res.resultEntity !== null && res.resultEntity !== undefined) {
          console.log('11')
          this.commit = '已认证'
        }
      })
    },
    // 获取用户信息
    getUserInfo () {
      // const info = {
      //   userCode: this.userCode
      // }
      this.$selfInfo.getUserInfo({}).then(res => {
        console.log('getUserInfoDetails', res)
        this.userInfo = res.resultEntity
        this.$cookie.set('userInfoDetails', this.userInfo)
      })
    },
    // 点击头像
    handleClickPersonal () {
      this.$router.push({ name: 'selfInfo' })
    },
    // 点击实名认证
    handleClickCommit () {
      if (this.commit === '未认证') {
        this.$router.push('/authenticateSelf')
      }
    },
    // 点击我的钱包
    handleClickPackage () {
      this.$dialog.alert({ message: '暂未开放该功能!' })
      // this.$router.push({ name: 'Test' })
    },
    // 点击共享管理
    handleClickShare () { this.$dialog.alert({ message: '暂未开放该功能!' }) },
    // 点击车辆管理
    handleClickCarMana () {
      //  this.$dialog.alert({ message: '暂未开放该功能!' })
      this.$router.push('/carManagement')
    },
    // 点击帮助中心
    handleClickHelp () {
      this.$router.push('/helpCenter')
      // this.$dialog.alert({ message: '暂未开放该功能!' })
    },
    // 点击意见反馈
    handleClickFedBack () {
      //  this.$dialog.alert({ message: '暂未开放该功能!' })
      this.$router.push('/problemFeedBack')
    },
    // 点击关于我们
    handleClickAbout () {
      // this.$dialog.alert({ message: '暂未开放该功能!' })
      this.$router.push('/aboutOur')
    },
    // 点击通知管理
    handleClickInform () {
      // this.$dialog.alert({ message: '暂未开放该功能!' })
      this.$router.push('/inform')
    },
    onChange (index) {
      console.log(index)
    },
    handleClickHomePage () {
      this.$router.replace('/homePage')
    },
    handleClickPark () {
      this.$router.replace('/carParking')
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  // 顶部背景图
  .top {
    width: 100%;
    height: 276.5px;
    background-image: url('../../assets/mySelf/top.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    // 铃铛图标
    .alarm {
      width: 18px;
      height: 20px;
      margin-left: 86.8%;
      padding-top: 63px;
    }
    .lineHead {
      width: 93.2%;
      height: 73px;
      margin-left: 3.33%;
      // 头像
      .head {
        width: 73px;
        height: 73px;
        margin-top: 21px;
        border-radius: 50%;
        overflow: hidden;
      }
      // 电话号码样式
      .inlinePhone {
        width: 100%;
        height: 23px;
        margin-top: 30px;
        margin-left: 2%;
        color: white;
        font-size: 19px;
      }
      // 修改按钮样式
      .xiugai {
        height: 14px;
        width: 13.5px;
        margin-left: 80%;
      }
      // 认证样式
      .inlineCommit {
        width: 100%;
        height: 23px;
        margin-top: 10px;
        margin-left: 2.5%;
        color: white;
        font-size: 15px;
        // 认证图标样式
        .commit {
          width: 13.5px;
          height: 16px;
        }
        // 认证字体
        .commitText {
          margin-left: 2.5%;
        }
      }
    }
  }
  // 内容部分
  .content {
    width: 93.6%;
    height: 75px;
    margin-left: 3.2%;
    position: relative;
    margin-top: 220px;
    line-height: 50px;
    color: #333333ff;
    font-size: 15px;
    .contentDiv {
      background-color: white;
      border-bottom: 0.5px solid #f0f0f0ff;

      .img {
        margin-top: 13px;
      }
    }
  }
  // 底部
  .down {
    width: 100%;
    height: 40px;
  }
}
</style>
